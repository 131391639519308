import { Api } from "../../../../../src/Api";
import {
  FEES,
  INDX_KEYS,
  KEYSx,
  MAIN_TITLE_LOGO,
} from "../../../../../src/components/IFunds/IFunds";
import {
  GetDayOfYear,
  getDeviceType,
  isMarketOpen,
  SnakeToNormal,
} from "../../../../../src/lib/utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  calculateCumulativePercentChange,
  calculatePercentageOfBar,
  getMaxValue,
  getMinValue,
} from "../..";
import { LineChartComp } from "../LineChart";
import {
  nFormatter,
  numberWithCommasAndDecimal,
} from "../../../../../src/Utils/helper";
import moment from "moment";
import { BarChartComp } from "../BarChart";
import { parseNumber } from "devextreme/localization";
import LiveChartComp from "../LiveChart";
//   calculate stats

function calculateChangeForPeriod(dataArray, period) {
  // Helper function to parse dates in "DD-MM-YYYY" format
  function parseDate(dateString) {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }

  // Helper function to adjust date by a given number of months
  function adjustDateByMonths(date, months) {
    const adjustedDate = new Date(date);
    adjustedDate.setMonth(adjustedDate.getMonth() - months);
    adjustedDate.setDate(adjustedDate.getDate() - 1);
    return adjustedDate;
  }
  function adjustDateByMonths2(date, months) {
    const adjustedDate = new Date(date);
    adjustedDate.setMonth(adjustedDate.getMonth() - months);
    adjustedDate.setDate(adjustedDate.getDate() - 2);
    return adjustedDate;
  }

  // Find the most recent date
  const mostRecentData = dataArray[dataArray?.length - 1];

  const mostRecentDate = parseDate(mostRecentData?.label);
  const mostRecentPrice = mostRecentData?.value;

  // Calculate the target date based on the period
  let targetDate;
  switch (period) {
    case "1month":
      targetDate = adjustDateByMonths(mostRecentDate, 1);
      break;
    case "3months":
      targetDate = adjustDateByMonths(mostRecentDate, 3);
      break;
    case "6months":
      targetDate = adjustDateByMonths(mostRecentDate, 6);
      break;
    case "1year":
      targetDate = adjustDateByMonths2(mostRecentDate, 12);
      break;
    default:
      targetDate = adjustDateByMonths2(mostRecentDate, 12);
  }

  // Find the closest date in the array to the target date
  const targetPriceData = dataArray.reduce((closest, current) => {
    const currentDiff = Math.abs(parseDate(current?.label) - targetDate);
    const closestDiff = Math.abs(parseDate(closest?.label) - targetDate);
    return currentDiff < closestDiff ? current : closest;
  });

  const targetPrice = targetPriceData?.value;
  // console.log(period, targetPriceData)

  // Calculate the percentage change
  const percentageChange =
    ((mostRecentPrice - targetPrice) / targetPrice) * 100;

  return percentageChange;
}

function calculateYTDChange(dataArray) {
  // Helper function to parse dates in "DD-MM-YYYY" format
  function parseDate(dateString) {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }

  // Find the price on the last day of the previous year (31-12-2023)
  const lastYearDate = parseDate("31-12-2023");
  const mostRecentDate = new Date(
    Math.max(...dataArray.map((x) => parseDate(x?.label)))
  );

  const lastYearPriceData = dataArray.find(
    (x) => parseDate(x?.label).toDateString() === lastYearDate.toDateString()
  );
  const mostRecentPriceData = dataArray.find(
    (x) => parseDate(x?.label).toDateString() === mostRecentDate.toDateString()
  );

  if (!lastYearPriceData || !mostRecentPriceData) {
    // throw new Error("Could not find prices for the specified dates.");
  }

  const lastYearPrice = lastYearPriceData?.value;
  const mostRecentPrice = mostRecentPriceData?.value;

  // Calculate the YTD percentage change
  const percentageChange =
    ((mostRecentPrice - lastYearPrice) / lastYearPrice) * 100;

  return percentageChange;
}

// function to calculate items between two dates
const calculateItemsBetweenDates = (data, numberOfMonths) => {
  function parseDate(dateString) {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }

  if (numberOfMonths == "YTD") {
    const startDate = new Date(new Date().getFullYear(), 0, 1);
    const endDate = new Date();

    // Ensure startDate is at the beginning of the day and endDate is at the end of the day
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let len = data?.filter((x) => {
      const date = parseDate(x?.label);
      return date >= startDate && date <= endDate;
    });

    return len.length;
  } else {
    let endDate = new Date();
    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - numberOfMonths);

    // Ensure startDate is at the beginning of the day and endDate is at the end of the day
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let len = data?.filter((x) => {
      const date = parseDate(x?.label);
      return date >= startDate && date <= endDate;
    });

    return len.length;
  }
};

const calculateStat = (data, days) => {
  const slicedData = data?.slice(data?.length - days, data?.length);
  return slicedData?.map((x, i) => {
    return i === 0
      ? 0
      : calculateCumulativePercentChange(slicedData[0]?.value, x?.value);
  })[days - 1];
};

export const calculateTransData = (data, days, initialPrice, color) => {
  return data?.map((x, i) => {
    return {
      value:
        i === 0 ? 0 : calculateCumulativePercentChange(initialPrice, x?.value),
      label: x?.label,
      color: color,
    };
  });
};

const getDaysForTime = (chartTime, lineChart) => {
  switch (chartTime) {
    case "1 Month":
      return calculateItemsBetweenDates(lineChart, 1);
    case "3 Month":
      return calculateItemsBetweenDates(lineChart, 3);

    case "6 Month":
      return calculateItemsBetweenDates(lineChart, 6);
    case "1 Year":
      return calculateItemsBetweenDates(lineChart, 12);
    case "YTD":
      return calculateItemsBetweenDates(lineChart, "YTD");
    default:
      return null;
  }
};

const CHART_TIMES = [
  {
    name: "1D",
    value: "LIVE",
    index: "Global Multi Sector Index",
  },
  {
    name: "1M",
    value: "1 Month",
  },
  {
    name: "3M",
    value: "3 Month",
  },
  {
    name: "6M",
    value: "6 Month",
  },
  {
    name: "1Y",
    value: "1 Year",
  },
  {
    name: "YTD",
    value: "YTD",
  },
  {
    name: "ALL",
    value: "All Time",
  },
];

const Overview = ({
  liveChanges,
  livePrice,
  equitySum,
  metrics,
  portSum,
  fundDetails,
  liveChart,
  lineChart,
  fullReturnData,
  priceData,
  prevlivePrice,
}) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const { pathname } = useLocation();
  let query = useQuery();
  let menu = Number(query.get("menu"));

  let isIndex = pathname === "/fund" ? false : true;

  const [chartData, setChartData] = useState([]);
  const [perfStat, setperfStat] = useState([]);
  const [chartTime, setchartTime] = useState("All Time");

  // console.log(">>> ", lineChart);

  useEffect(() => {
    if (portSum) {
      setchartTime(
        isIndex && portSum?.portfolioName === "Global Multi Sector Index"
          ? "1 Day"
          : "All Time"
      );
    }
  }, [portSum, isIndex]);

  const [wkRng4, setwkRng4] = useState({
    min: 0,
    max: 0,
  });
  const [wkRng52, setwkRng52] = useState({
    min: 0,
    max: 0,
  });

  useEffect(() => {
    if (lineChart?.length) {
      const dataLength = lineChart.length;
      const getDayOfYear = GetDayOfYear();

      const stat = {
        "1 Month":
          portSum?.portfolioName === "Global Multi Sector Index"
            ? calculateChangeForPeriod(lineChart, "1month")
            : calculateChangeForPeriod(lineChart, "1month"),
        "3 Month":
          portSum?.portfolioName === "Global Multi Sector Index"
            ? calculateChangeForPeriod(lineChart, "3months")
            : calculateChangeForPeriod(lineChart, "3months"),
        "6 Month":
          portSum?.portfolioName === "Global Multi Sector Index"
            ? calculateChangeForPeriod(lineChart, "6months")
            : calculateChangeForPeriod(lineChart, "6months"),
        "1 Year":
          portSum?.portfolioName === "Global Multi Sector Index"
            ? calculateChangeForPeriod(lineChart, "1year")
            : calculateChangeForPeriod(lineChart, "1year"),
        YTD:
          portSum?.portfolioName === "Global Multi Sector Index"
            ? calculateStat(lineChart, getDayOfYear)
            : calculateYTDChange(lineChart),
        "All Time": lineChart.map((x, i) => {
          return i === 0
            ? 0
            : calculateCumulativePercentChange(lineChart[0]?.value, x?.value);
        })[dataLength - 1],
      };

      setperfStat(stat);
    }
  }, [lineChart]);

  //   get data based on time
  useEffect(() => {
    if (lineChart?.length) {
      const color = MAIN_TITLE_LOGO[isIndex ? 4 : menu]?.color;
      const days = getDaysForTime(chartTime, lineChart);

      if (days) {
        const transdata = lineChart.slice(
          lineChart.length - days,
          lineChart.length
        );
        const initialPrice = transdata[0]?.value;
        setChartData(calculateTransData(transdata, days, initialPrice, color));
      } else {
        setChartData(
          lineChart.map((x, i) => ({
            value:
              i === 0
                ? 0
                : calculateCumulativePercentChange(
                    lineChart[0]?.value,
                    x?.value
                  ),
            label: x?.label,
            color: color,
          }))
        );
      }
    }
  }, [lineChart, chartTime]);

  useEffect(() => {
    if (priceData?.length) {
      // Extract the last 30 items
      const wkRng4Tem = priceData?.slice(-30);

      setwkRng4({
        min: Number(getMinValue(wkRng4Tem) ?? 0).toFixed(2),
        max: Number(getMaxValue(wkRng4Tem) ?? 0).toFixed(2),
      });

      // Extract the last 364 items
      const wkRng52Tem = priceData?.slice(-364);

      setwkRng52({
        min: Number(getMinValue(wkRng52Tem) ?? 0).toFixed(2),
        max: Number(getMaxValue(wkRng52Tem) ?? 0).toFixed(2),
      });
    }
  }, [priceData]);

  return (
    <div className="fade w-full">
      <div className="flex items-center w-full justify-between h-[1px] bg-[#58595b7d]" />
      {lineChart?.length > 0 && portSum?.currency?.code ? (
        <div className="flex flex-col min-h-fit mt-4 w-full p-3 px-4 flex justify-between items-start rounded-xl bg-[#35353538]">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col justify-start items-start">
              <text className="text-lg font-bold">Performance</text>
              {!isIndex && (
                <div className="text-sm text-zinc-400">
                  Since Inception, {portSum?.currency?.code} 10,000 would be
                  worth {portSum?.currency?.code}{" "}
                  {Math.round(
                    Number(10000 * (1 + Number(perfStat["All Time"] / 100)))
                  ).toLocaleString()}{" "}
                  today
                </div>
              )}
            </div>

            <div
              className={`${getDeviceType() === "mobile" && "hidden"} md:flex`}
            >
              {CHART_TIMES?.filter((x) => {
                if (x.value === "LIVE") {
                  return x.index === portSum?.portfolioName;
                }
                return true;
              })?.map((x) => {
                return (
                  <div
                    onClick={() => {
                      setchartTime(x?.value !== "LIVE" ? x?.value : "1 Day");
                    }}
                    className={`ml-2 w-8 h-8 ${
                      chartTime == x?.value ||
                      (chartTime === "1 Day" && x?.name == "1D")
                        ? "bg-[#58595b7d]"
                        : ""
                    } hover:bg-[#3333335d] rounded-lg text-sm cursor-pointer flex justify-center items-center`}
                  >
                    {x?.name}
                  </div>
                );
              })}
            </div>
          </div>
          {!lineChart[0]?.color ? (
            <div className="w-full h-[150px] md:h-[300px] flex justify-center items-center">
              <div className="flex flex-col w-full mt-4 animate-pulse">
                <div className=" w-[100%] h-[360px] bg-zinc-900  rounded-lg mb-2.5" />
              </div>
            </div>
          ) : chartTime == "1 Day" ? (
            <LiveChartComp
              dataChart={liveChart}
              prevlivePrice={prevlivePrice}
            />
          ) : (
            <LineChartComp data={chartData} />
          )}
          <div className="mt-3 w-full flex justify-between px-2 overflow-x-auto no-scrollbar ">
            {/* performanceTabData */}
            {CHART_TIMES?.filter((x) => {
              if (x.value === "LIVE") {
                return x.index === portSum?.portfolioName;
              }
              return true;
            })?.map((x) => {
              return (
                <div
                  onClick={() => {
                    setchartTime(x?.value !== "LIVE" ? x?.value : "1 Day");
                  }}
                  className={`cursor-pointer min-w-[150px] md:w-[14%] h-[60px] ${
                    chartTime === x?.value ||
                    (chartTime === "1 Day" && x?.value === "LIVE")
                      ? "bg-zinc-900 "
                      : ""
                  } rounded-lg justify-center items-center flex flex-col hover:bg-zinc-900`}
                >
                  <text className="text-sm text-muted text-base">
                    {x?.value == "LIVE" ? "1 Day" : x?.value}
                  </text>
                  <div className="flex items-center justify-center">
                    {isMarketOpen(isIndex) && x?.value == "LIVE" && (
                      <div className="animate-pulse w-2.5 h-2.5 bg-red-700 rounded-full mr-2 mt-1" />
                    )}
                    <text
                      className={`${
                        (x?.name === "1D"
                          ? Number(liveChanges?.percentageChange ?? 0)
                          : Number(perfStat[x?.value] ?? 0)) > 0
                          ? "text-green-400"
                          : "text-red-500"
                      } font-bold`}
                    >
                      {x?.name === "1D"
                        ? Number(liveChanges?.percentageChange ?? 0).toFixed(
                            2
                          ) + "%"
                        : Number(perfStat[x?.value] ?? 0).toFixed(2) + "%"}
                    </text>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full mt-4 animate-pulse">
          <div className=" w-[100%] h-[360px] bg-zinc-900  rounded-lg mb-2.5" />
        </div>
      )}

      {/* Range Cards */}
      {lineChart?.length > 0 ? (
        <div className="min-h-fit mt-4 w-full grid grid-cols-1 md:grid-cols-3 gap-4">
          <RangeCard
            title="4 Week Range"
            min={wkRng4?.min}
            max={wkRng4?.max}
            barData={fullReturnData?.slice(
              Math.max(fullReturnData?.length - 30, 0),
              fullReturnData?.length
            )}
            livePrice={livePrice}
            portSum={portSum}
          />
          <RangeCard
            title="52 Week Range"
            min={wkRng52?.min}
            max={wkRng52?.max}
            barData={fullReturnData?.slice(
              Math.max(fullReturnData?.length - 364, 0),
              fullReturnData?.length
            )}
            livePrice={livePrice}
            portSum={portSum}
          />
          <div className="flex flex-col justify-start items-start min-h-fit w-full p-3 px-4 flex rounded-xl bg-[#58595b30] col-span-1">
            <text className="text-muted">Asset Class</text>
            <div className="flex justify-between items-center w-full mt-2">
              <text className="text-2xl font-bold mr-1">
                {SnakeToNormal(equitySum?.securityType ?? "")}
              </text>
            </div>
          </div>
        </div>
      ) : (
        <div className="animate-pulse min-h-fit mt-4 w-full grid grid-cols-1 md:grid-cols-3 gap-4">
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </div>
      )}

      {/* Description Data */}
      <AboutSection equitySum={equitySum} />

      {/* Fund Characteristics */}
      <div className="min-h-fit mt-4 w-full grid grid-cols-1 md:grid-cols-2 gap-4">
        {lineChart[lineChart?.length - 3]?.nav ? (
          <div className="flex flex-col justify-start items-start min-h-fit  w-full p-3 px-4 flex rounded-xl bg-[#58595b30] col-span-1">
            <text className="text-2xl font-bold mr-1">Key Facts</text>

            {[
              {
                label: isIndex
                  ? `Total Market Cap (${portSum?.currency?.code ?? "GHS"})`
                  : `Net Assets of Funds (${portSum?.currency?.code ?? "GHS"})`,
                value: nFormatter(
                  lineChart[lineChart?.length - 1]?.nav ??
                    lineChart[lineChart?.length - 2]?.nav ??
                    lineChart[lineChart?.length - 3]?.nav ??
                    lineChart[lineChart?.length - 4]?.nav ??
                    lineChart[lineChart?.length - 5]?.nav ??
                    portSum?.nav ??
                    equitySum?.nav ??
                    0
                ),
              },
              {
                label: "Valuation Methodology",
                value: isIndex ? "N/A" : "Mark to Market",
              },
              // {
              //   label: "Benchmark",
              //   value: isIndex ? "N/A" : fundDetails?.benchmarkName,
              // },
              {
                label: "Fund Inception",
                value: lineChart[0]
                  ? moment(
                      lineChart[0]?.label?.split("-")[2] +
                        "-" +
                        lineChart[0]?.label?.split("-")[1] +
                        "-" +
                        lineChart[0]?.label?.split("-")[0]
                    )?.format("DD, MMM yyyy")
                  : "--",
              },
              {
                label: "Asset Type",
                value:
                  equitySum?.assetClass == "CIS"
                    ? "Mutual Fund"
                    : SnakeToNormal(equitySum?.assetClass ?? "") ?? "",
              },
            ]?.map((x) => {
              return (
                <div className="flex justify-between items-center w-full mt-2 mb-1">
                  <text className="text-muted">{x?.label}</text>
                  <text className="text-white font-bold">{x?.value}</text>
                </div>
              );
            })}

            {!isIndex &&
              [
                {
                  label: "Management Fee (%)",
                  value: isIndex ? "N/A" : FEES[menu - 1]?.management,
                },
                {
                  label: "Custody Fee (%)",
                  value: isIndex ? "N/A" : FEES[menu - 1]?.custody_fee,
                },
                {
                  label: "Administrative Fee (%)",
                  value: isIndex ? "N/A" : FEES[menu - 1]?.administrative_fee,
                },
                {
                  label: "Loads",
                  value: isIndex ? "N/A" : FEES[menu - 1]?.loads,
                },
              ]?.map((x) => {
                return (
                  <div className="flex justify-between items-center w-full mt-2 mb-1">
                    <text className="text-muted">{x?.label}</text>
                    <text className="text-white font-bold">{x?.value}</text>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="h-[500px]  w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
        )}

        {metrics?.averageReturn ? (
          <div className="flex flex-col justify-start items-start min-h-fit  w-full p-3 px-4 flex rounded-xl bg-[#58595b30] col-span-1">
            <text className="text-2xl font-bold mr-1">
              Portfolio Characteristics
            </text>

            {[
              {
                label: `Closing Price (${portSum?.currency?.code ?? "GHS"})`,
                value:
                  numberWithCommasAndDecimal(
                    lineChart[lineChart?.length - 1]?.unitPrice ||
                      lineChart[lineChart?.length - 1]?.value ||
                      prevlivePrice,
                    4
                  ) || "━━",
              },
              {
                label: "Average Return (%)",
                value:
                  numberWithCommasAndDecimal(metrics?.averageReturn) || "━━",
              },
              {
                label: "Standard Deviation (%)",
                value:
                  numberWithCommasAndDecimal(metrics?.standardDeviation) ||
                  "━━",
              },
              {
                label: "Average Gain (%)",
                value: numberWithCommasAndDecimal(metrics?.averageGain) || "━━",
              },
              {
                label: "Average Loss (%)",
                value: numberWithCommasAndDecimal(metrics?.averageLoss) || "--",
              },
              // {
              //   label: "Realised Income",
              //   value: nFormatter(portSum?.realisedIncome) || "--",
              // },
              // {
              //   label: "UnRealised Income",
              //   value: nFormatter(portSum?.unRealisedIncome) || "--",
              // },
            ]?.map((x) => {
              return (
                <div className="flex justify-between items-center w-full mt-2 mb-1">
                  <text className="text-muted">{x?.label}</text>
                  <text className="text-white font-bold">{x?.value}</text>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="h-[500px]  w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
        )}
      </div>

      {/* Asset Allocation */}
      <AssetAllocation
        portSum={portSum}
        nav={
          lineChart[lineChart?.length - 1]?.nav ??
          lineChart[lineChart?.length - 2]?.nav ??
          lineChart[lineChart?.length - 3]?.nav ??
          lineChart[lineChart?.length - 4]?.nav ??
          lineChart[lineChart?.length - 5]?.nav ??
          portSum?.nav ??
          equitySum?.nav ??
          0
        }
      />

      {/* Other fund Infomation */}
      {/* {!isIndex && (
        <div className="min-h-fit mt-4 w-full grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col justify-start items-start min-h-fit  w-full p-3 px-4 flex rounded-xl bg-[#35353538] col-span-1">
            <text className="text-2xl font-bold mr-1">Rating</text>

            {[
              {
                label: "Rating",
                value: isIndex ? "N/A" : "N/A",
              },
              {
                label: "Category Benchmark",
                value: isIndex ? "N/A" : "N/A",
              },
              {
                label: "Substainbility Ration",
                value: isIndex ? "N/A" : "N/A",
              },
            ]?.map((x) => {
              return (
                <div className="flex justify-between items-center w-full mt-2 mb-1">
                  <text className="text-muted">{x?.label}</text>
                  <text className="text-white font-bold">{x?.value}</text>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col justify-start items-start min-h-fit  w-full p-3 px-4 flex rounded-xl bg-[#35353538] col-span-1">
            <text className="text-2xl font-bold mr-1">Fees</text>

            {[
              {
                label: "Management Fee (%)",
                value: isIndex ? "N/A" : FEES[menu - 1]?.management,
              },
              {
                label: "Custody Fee (%)",
                value: isIndex ? "N/A" : FEES[menu - 1]?.custody_fee,
              },
              {
                label: "Administrative Fee (%)",
                value: isIndex ? "N/A" : FEES[menu - 1]?.administrative_fee,
              },
              {
                label: "Loads",
                value: isIndex ? "N/A" : FEES[menu - 1]?.loads,
              },
            ]?.map((x) => {
              return (
                <div className="flex justify-between items-center w-full mt-2 mb-1">
                  <text className="text-muted">{x?.label}</text>
                  <text className="text-white font-bold">{x?.value}</text>
                </div>
              );
            })}
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Overview;

const SkeletonCard = () => (
  <div className="animate-pulse flex flex-col justify-start items-start min-h-[100px] w-full p-3 px-4 flex rounded-xl bg-[#58595b30] col-span-1" />
);

const RangeCard = ({ title, min, max, barData, livePrice, portSum }) => {
  const percentageNegative = calculatePercentageOfBar(
    barData
      .filter((x) => Number(x.value) < 0)
      .reduce((x, y) => x + Math.abs(y.value), 0),
    barData?.reduce((x, y) => x + Math.abs(y.value), 0)
  );

  const percentagePositive = calculatePercentageOfBar(
    barData
      .filter((x) => Number(x.value) >= 0)
      .reduce((x, y) => x + Math.abs(y.value), 0),
    barData?.reduce((x, y) => x + Math.abs(y.value), 0)
  );

  const markerPosition = calculatePercentageOfBar(
    min,
    max,
    livePrice || Number(barData[barData?.length - 1]?.unitPrice)
  );

  return (
    <div className="flex flex-col justify-start items-start min-h-fit w-full p-3 px-4 flex rounded-xl bg-[#58595b30] col-span-1">
      <text className="text-muted">{title}</text>
      <div className="flex justify-between items-center w-full mt-1">
        <div className="flex flex-row justify-end items-end">
          <text className="text-lg font-bold mr-1">
            {numberWithCommasAndDecimal(min) ?? 0}
          </text>
          <text className="text-sm text-gray-400">
            {portSum?.currency?.code}
          </text>
        </div>
        <div className="flex flex-row justify-end items-end">
          <text className="text-lg font-bold mr-1">
            {numberWithCommasAndDecimal(max) ?? 0}
          </text>
          <text className="text-sm text-gray-400">
            {portSum?.currency?.code}
          </text>
        </div>
      </div>
      <div className="flex justify-between items-center w-full mt-1.5 relative">
        <div
          style={{ width: percentagePositive }}
          className="bg-red-400 h-1 rounded-full"
        />
        <div
          style={{ width: percentageNegative }}
          className="bg-green-400 h-1 rounded-full"
        />

        <div
          style={{ marginLeft: markerPosition }}
          className="bg-white w-2 h-2 rounded-full absolute -top-0.5 left-0"
        />
      </div>
    </div>
  );
};

export const processAssetTypeDetails = (assetTypeDetails) => {
  if (!assetTypeDetails || assetTypeDetails.length === 0) return [];

  let tempObj = {
    "Cash & Cash Equivalents": 0.0,
    "Fixed Income": 0.0,
    Equities: 0.0,
  };

  const fixedIncomeKeys = [
    "Bond",
    "Bill",
    "Corporate Bonds",
    "Government Bonds",
    "Municipal Bonds",
    "Government Bills",
    "Municipal Bills",
    "Fixed Deposits",
    "CIS - Fixed Income",
    "CIS - Money Money Market",
    "ETF - Currency",
    "ETF - Fixed Income",
  ];
  const equitiesKeys = [
    "Equity",
    "CIS",
    "ETF",
    "Ordinary Shares",
    "Preference shares",
    "Venture Capital",
    "CIS - Balanced",
    "CIS - Equity",
    "ETF - Commodity",
    "ETF - EQUITY",
  ];

  assetTypeDetails.forEach(({ exposure, exposureRatio }) => {
    const value = parseNumber(exposureRatio);

    if (exposure === "Cash") {
      tempObj["Cash & Cash Equivalents"] += value;
    } else if (fixedIncomeKeys.includes(exposure)) {
      tempObj["Fixed Income"] += value;
    } else if (equitiesKeys.includes(exposure)) {
      tempObj["Equities"] += value;
    }
  });

  const data_ = [];

  for (const key in tempObj) {
    data_.push({
      label: key,
      value: tempObj[key],
      // color: MAIN_TITLE_LOGO[isIndex ? 4: menu]?.color,
    });
  }

  return data_;
};

const AssetAllocation = ({ nav, portSum }) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const { pathname, search } = useLocation();
  let query = useQuery();
  let menu = Number(query.get("menu"));

  let KEYS = pathname == "/fund" ? KEYSx : INDX_KEYS;
  const [pieChartOfAssetClassChart, setPieChartOfAssetClassChart] = useState(
    []
  );

  // Memoize the fetch function to avoid re-creating it on every render
  const fetchData = useMemo(() => {
    return () => {
      if (portSum) {
        Api.getCISData(KEYS[menu - 1], parseFloat(nav ?? portSum?.nav))
          .then((response) => {
            if (response?.status) {
              sessionStorage.setItem(
                "cisData" + KEYS[menu - 1],
                JSON.stringify(response?.data)
              );
              const AllocationData = processAssetTypeDetails(
                response?.data?.assetClass?.ASSET_TYPE_DETAIL
              );
              setPieChartOfAssetClassChart(AllocationData);
              // Save data to sessionStorage
              sessionStorage.setItem(
                "pieChartData" + KEYS[menu - 1],
                JSON.stringify(AllocationData)
              );
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
  }, [portSum]);

  useEffect(() => {
    const savedData = sessionStorage.getItem("pieChartData" + KEYS[menu - 1]);
    if (savedData) {
      setPieChartOfAssetClassChart(JSON.parse(savedData));
    } else {
      fetchData();
    }
  }, [fetchData]);

  if (pieChartOfAssetClassChart?.length === 0) {
    return (
      <div className="mt-4 animate-pulse flex flex-col justify-start items-start min-h-[300px] w-full p-3 px-4 flex rounded-xl bg-[#58595b30] col-span-1" />
    );
  }

  return (
    <div className="flex flex-col min-h-[300px] mt-4 w-full p-3 px-4 flex justify-between items-start rounded-xl bg-[#35353538]">
      <div>
        <text className="text-lg font-bold">Asset Allocation</text>
      </div>
      <div className="w-full flex justify-between">
        <div
          className={`${getDeviceType() === "mobile" ? "w-[100%]" : "w-[89%]"}`}
        >
          <BarChartComp data={pieChartOfAssetClassChart} />
        </div>
        <div
          className={`${
            getDeviceType() === "mobile" ? "w-[30%]" : "w-[10%]"
          } -mt-10 flex flex-col`}
        >
          <div
            className={`${
              getDeviceType() === "mobile" ? "-mt-3" : "mt-6"
            } mb-0 text-right`}
          >
            % Net Assets
          </div>
          {pieChartOfAssetClassChart
            ?.sort((a, b) => b?.value - a?.value)
            ?.map((x, i) => {
              return (
                <div className="h-[45px] flex justify-end items-end mb-1">
                  {Number(x?.value).toFixed(2) ?? 0}%
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const AboutSection = ({ equitySum }) => {
  const [showMore, setShowMore] = useState(false);
  const contentRef = useRef(null);

  const description = String(equitySum?.description ?? "");

  const isDesktop = window.innerWidth >= 1024; // Assuming 1024px as the breakpoint for desktop

  useEffect(() => {
    if (isDesktop && contentRef.current) {
      if (showMore) {
        // When expanding, set maxHeight to the full content height
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
      } else {
        // When collapsing, set maxHeight first to the current height to trigger the transition
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
        // Force a reflow (repaint) before collapsing
        //   contentRef.current.offsetHeight; // This line forces the reflow
        // Now set maxHeight to the collapsed height
        contentRef.current.style.maxHeight = "96px";
      }
    }
  }, [showMore, isDesktop]);

  let wordCount = getDeviceType() === "mobile" ? 155 : 500;

  return (
    <div className="flex flex-col justify-start items-start min-h-fit mt-4 w-full rounded-xl transition-all fade-in-10">
      <span className="text-xl font-bold">About</span>

      <div
        ref={contentRef}
        className="overflow-hidden transition-all duration-500 ease-in-out"
        style={{ maxHeight: isDesktop ? "96px" : "none" }}
      >
        {description.substring(
          0,
          showMore || description?.length <= wordCount
            ? description.length
            : wordCount
        ) + (!showMore && description?.length > wordCount ? "..." : "")}
      </div>

      {description?.length > wordCount && (
        <span
          onClick={() => setShowMore(!showMore)}
          className="cursor-pointer font-bold mt-2"
        >
          {showMore ? "See Less" : "See More"}
        </span>
      )}
    </div>
  );
};
